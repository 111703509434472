body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
        "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
        "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-image: url("../src/images/safakhq.jpg");
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    width: 100%;
    background-position: center top;
    font-family: "Epilogue", sans-serif;
    overflow-y: scroll;
    @supports (-webkit-touch-callout: inherit) {
        background-attachment: scroll;
    }
}

.background {
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: left center;
    /* min-width: 100vw; */
    /* min-height: 100vh; */
    z-index: -1;
    position: fixed;
}

.App {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    /* background-image: url("../src/images/safakhq.jpg");
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    font-family: "Epilogue", sans-serif;
    overflow-y: scroll;
    background-position: center center;
    width: 100vw; */
}

div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

h2 {
    color: rgba(255, 255, 255, 0.74);
    font-style: italic;
}

h3 {
    color: rgba(255, 255, 255, 0.74);
    font-style: italic;
}

.nav_bar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: rgba(44, 44, 44, 0.829);
    margin: 10px;
    border-radius: 20px;
    /* position: fixed; */
    top: 5vh;
}

.nav_bar a {
    margin: 10px;
}

.up {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 20vh;
}

.main {
    /* display: grid;
    grid-template-columns: 50% 50%; */
    width: 90vw;
    min-height: 70vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    /* position: fixed; */
    /* top: 25vh; */
}

.social {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: rgba(44, 44, 44, 0.829);
    border-radius: 20px;
    /* position: fixed; */
    top: 15vh;
}

.social_img {
    height: 2.5rem;
    margin: 10px;
}

.tweets {
    /* grid-column-start: 2; */
    /* height: 50vh; */
    opacity: 0.9;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 80vw;
    height: 60vh;
}

.tweets-safari {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    width: 80vw;
}

.tweets-safari div {
    width: 30vw;
}

.tweets-safari div iframe {
    width: 600px;
}

/* .tweets div {
    width: 70vw;
} */

iframe {
    border-radius: 20px;
    width: 100%;
}

/* iframe::after {
    width: 400px;
} */

/* .tweets div iframe {
    border-radius: 20px;
    width: 40vw;
} */

.kimdir {
    opacity: 0.9;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
    width: 80vw;
    height: 60vh;
}

.kimdir p {
    border-radius: 20px;
    width: 40vw;
    max-height: 55vh;
    overflow-y: auto;
    background-color: rgba(44, 44, 44, 0.829);
    color: white;
    text-align: center;
    padding: 10px;
}

.etkinlikler {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 25vh;
    background-color: rgba(44, 44, 44, 0.6);
    border-radius: 20px;
    width: 90vw;
    margin-top: 10px;
    /* overflow-y: scroll; */
}

.etkinlik {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background-color: rgba(44, 44, 44, 0.829); */
    border-radius: 20px;
    border: 2px solid rgba(255, 255, 255, 0.74);
    margin: 10px;
    padding: 10px;
    color: rgba(255, 255, 255, 0.74);
}

.etkinlik-gelecek {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 2px solid rgba(255, 255, 255, 0.74);
    border-radius: 20px;
    margin: 10px;
    padding: 10px;
    color: rgba(255, 255, 255, 0.74);
}

.etkinlik-gelecek * {
    /* font-style: normal; */
}

.gelecek-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px;
}

.gelecek img {
    width: 30vw;
    border-radius: 20px;
    opacity: 0.9;
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    margin-right: 10px;
}

.gelecek p {
    width: 30vw;
}

.etkinlik * {
    color: rgba(255, 255, 255, 0.74);
}

.gecmis {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
}

.gecmis img {
    width: 15vw;
    border-radius: 20px;
    opacity: 0.9;
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
    margin-bottom: 10px;
}

.etkinlik-gecmis {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px solid rgba(255, 255, 255, 0.74);
    border-radius: 20px;
    margin: 10px;
    padding: 10px;
    color: rgba(255, 255, 255, 0.74);
    width: 20vw;
    /* max-height: 50vh; */
    overflow-y: auto;
}

.etkinlik-gecmis p {
    width: 15vw;
    min-height: 10vh;
    max-height: 20vh;
    overflow-y: auto;
    padding-right: 10px;
}

.footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 10vh;
    /* position: fixed; */
    bottom: 1vh;
}

a {
    text-decoration: none;
    color: white;
}

.gecmis-text {
    font-size: large;
}
h4 {
    margin: 5px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}
@media (hover: none) {
    body {
        background-attachment: initial;
    }
}

@media only screen and (min-device-width: 319px) and (max-device-width: 1025px) and (-webkit-min-device-pixel-ratio: 2) {
    body {
        background-position: left top;
    }

    .social_img {
        height: 2rem;
        margin: 10px;
    }

    .kimdir p {
        border-radius: 20px;
        width: 80vw;
        max-height: 55vh;
        overflow-y: scroll;
        background-color: rgba(44, 44, 44, 0.829);
        color: white;
        text-align: center;
        padding: 10px;
    }
    .tweets {
        /* grid-column-start: 2; */
        /* height: 50vh; */
        opacity: 0.9;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        /* width: 80vw;
        height: 60vh; */
    }

    .tweets div {
        width: 80vw;
        height: 60vh;
    }

    .tweets-safari {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 80vw;
        max-height: 50vh;
    }

    .tweets-safari div {
        width: 80vw;
        max-height: 60vh;
    }

    .tweets-safari div iframe {
        width: 300px;
        height: 300px;
    }

    .etkinlik-gelecek {
        flex-direction: column;
    }

    .gelecek img {
        width: 70vw;
    }

    .gelecek p {
        width: 70vw;
    }

    .etkinlik-gecmis {
        width: 70vw;
    }

    .gecmis {
        flex-direction: column;
    }

    .gecmis img {
        width: 70vw;
    }

    .gecmis p {
        width: 70vw;
    }
}
